import revive_payload_client_YlLrJgDVhg from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_kzpcyryhxue5pf33nzamcxuasm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_h3DbsWLI00 from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_kzpcyryhxue5pf33nzamcxuasm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_tl2VAp0Pux from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_kzpcyryhxue5pf33nzamcxuasm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_TEWCTh4OpD from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_kzpcyryhxue5pf33nzamcxuasm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_hHANmmejnp from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_kzpcyryhxue5pf33nzamcxuasm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GqkFeXvCoN from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_kzpcyryhxue5pf33nzamcxuasm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_xJ0n8sgUK0 from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_kzpcyryhxue5pf33nzamcxuasm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/checkup/checkup/apps/admin/.nuxt/components.plugin.mjs";
import prefetch_client_zeieMs0jpX from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_kzpcyryhxue5pf33nzamcxuasm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import supabase_client_Vu6b6nytQn from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/supabase.client.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/sentry.client.ts";
import dayjs_client_A2bzKURDlV from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/dayjs.client.ts";
import gtag_6Z9lu4VVCL from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/gtag.ts";
import i18n_YCWKwfVXF6 from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/i18n.ts";
import qrcode_client_gU8ufMWnQ5 from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/qrcode.client.ts";
import vuedraggable_ajhfMTWOKD from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/vuedraggable.ts";
export default [
  revive_payload_client_YlLrJgDVhg,
  unhead_h3DbsWLI00,
  router_tl2VAp0Pux,
  payload_client_TEWCTh4OpD,
  navigation_repaint_client_hHANmmejnp,
  check_outdated_build_client_GqkFeXvCoN,
  chunk_reload_client_xJ0n8sgUK0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_zeieMs0jpX,
  supabase_client_Vu6b6nytQn,
  sentry_client_KAXFuL2wum,
  dayjs_client_A2bzKURDlV,
  gtag_6Z9lu4VVCL,
  i18n_YCWKwfVXF6,
  qrcode_client_gU8ufMWnQ5,
  vuedraggable_ajhfMTWOKD
]